<template>
	<div class="login py-4">
		<b-container>
			<b-row class="justify-content-center">
				<b-col cols="10" sm="8" md="5" lg="4" class="text-center">
					<h1>Låst sida</h1>
					<p>Ange korrekt lösenord för att se innehållet</p>
					<b-form @submit.prevent="login" class="pt-3">
						<b-form-group>
							<b-input-group>
								<b-form-input type="password" v-model="password" autocomplete="password" placeholder="Lösenord"></b-form-input>
								<b-input-group-append>
									<b-button type="submit">Bekräfta</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
						<b-alert 	variant="danger"
									class="py-2"
									show
									v-if="error">
							{{ error }}
						</b-alert>
					</b-form>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				password: null,
				error: null
			}
		},
		methods: {
			login() {
				this.$store.dispatch('password', this.password)
				.then(() => {
					this.$router.push({ name: 'type', params: { slug: this.$route.params.slug, login: true } })
				})
			},
		},
		mounted() {
			this.error = this.$route.params.error
		}
	}
</script>
