var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.asset.downloadable
    ? _c(
        "b-col",
        { staticClass: "col-12 col-lg-4" },
        [
          _c("p", { staticClass: "title" }, [_vm._v(" Ladda ner ")]),
          !_vm.downloading
            ? _c(
                "b-row",
                [
                  _vm._l(_vm.downloads, function (download) {
                    return _c(
                      "b-col",
                      { key: download.id, staticClass: "col-12" },
                      [
                        _c(
                          "li",
                          {
                            staticClass: "download",
                            on: {
                              click: function ($event) {
                                return _vm.downloadFormat(download)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(download.format.name) +
                                " (" +
                                _vm._s(download.format.height) +
                                "p)"
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  _c("b-col", { staticClass: "col-12" }, [
                    _c(
                      "li",
                      {
                        staticClass: "download",
                        on: {
                          click: function ($event) {
                            return _vm.downloadOriginal()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "Original (" +
                            _vm._s(this.asset.original_format.format.height) +
                            "p)"
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              )
            : _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "radial-progress-bar",
                        {
                          attrs: {
                            diameter: 100,
                            "completed-steps": _vm.downloading,
                            "total-steps": 100,
                            animateSpeed: 1000,
                            startColor: "#F5AAAD",
                            stopColor: "#F5AAAD",
                            innerStrokeColor: "white",
                            strokeWidth: 5,
                          },
                        },
                        [
                          _c("p", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.downloading) + "%"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }