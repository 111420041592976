var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "asset" } },
    [
      _vm.category && _vm.asset && _vm.loaded
        ? _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "h3",
                          attrs: {
                            to: {
                              name: "category",
                              params: {
                                slug: _vm.type.uuid
                                  ? _vm.category.category_key
                                  : _vm.category.slug,
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.category.title))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "align-h": "center" } },
                    [
                      _vm.asset.type !== 2
                        ? _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "12",
                                md:
                                  _vm.asset.ratio === "1:1"
                                    ? "9"
                                    : _vm.asset.ratio === "4:3"
                                    ? "11"
                                    : "12",
                                lg:
                                  _vm.asset.ratio === "1:1"
                                    ? "8"
                                    : _vm.asset.ratio === "4:3"
                                    ? "10"
                                    : "12",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "bg-black embed-responsive",
                                  class: _vm.getRatio().class,
                                },
                                [
                                  _c("iframe", {
                                    staticClass: "embed-responsive-item",
                                    attrs: {
                                      src: _vm.src,
                                      allowfullscreen: "allowfullscreen",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("b-img", {
                                staticClass: "display-img",
                                attrs: { src: _vm.asset.download_url },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "pt-4" },
                    [
                      _c("b-col", { staticClass: "col-12 col-lg-8" }, [
                        _c("h2", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.asset.title)),
                        ]),
                        _c("p", [_vm._v(_vm._s(_vm.asset.description))]),
                      ]),
                      _c("Downloads"),
                    ],
                    1
                  ),
                  _c("Assets"),
                ],
                1
              ),
            ],
            1
          )
        : _c("b-container", [_c("Loading")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }