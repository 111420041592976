<template>
  <div></div>
</template>

<script>
export default {
  methods: {
    type() {
      this.$store.dispatch("type", this.$route.params.slug)
        .then(response => {
          this.$router.push({
            name: response.type,
            params: { slug: this.$route.params.slug, login: this.$route.params.slug }
          })
        })
        .catch(err => {
            console.log(err);
            this.$router.push({
              name: 'notfound',
              params: { slug: this.$route.params.slug }
          })
        })
    }
  },

  mounted() {
    this.type();
  }
};
</script>