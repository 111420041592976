<template>
    <b-col class="col-12 col-lg-4" v-if="asset.downloadable">
        <p class="title">
          Ladda ner
        </p>
        <b-row v-if="!downloading">
          <b-col class="col-12"  v-for="download in downloads" :key="download.id">
            <li class="download" @click="downloadFormat(download)">{{download.format.name}} ({{download.format.height}}p)</li>
          </b-col>
          <b-col class="col-12">
            <li class="download" @click="downloadOriginal()">Original ({{this.asset.original_format.format.height}}p)</li>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <radial-progress-bar :diameter="100"
                                :completed-steps="downloading"
                                :total-steps="100"
                                :animateSpeed="1000"
                                startColor="#F5AAAD"
                                stopColor="#F5AAAD"
                                innerStrokeColor="white"
                                :strokeWidth="5"
                                >
            <p class="title">{{ downloading }}%</p>
            </radial-progress-bar>
          </b-col>
        </b-row>
    </b-col>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
export default {
  components: {
    RadialProgressBar
  },
  computed: {
    asset() {
      return this.$store.getters.asset;
    },
    downloads() {
      return this.asset.asset_format.filter(a => a.format.type === "download");
    },
    downloading() {
      return this.$store.getters.downloading;
    }
  },
  methods: {
    downloadOriginal() {
      this.$store.dispatch('downloadFromUrl', { url: this.asset.original_format.download_url })
      .then(response => {
        console.log('downloading response');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')
        link.href = url;
        link.setAttribute('download', this.asset.original_format.format.filename)
        link.click()
      })
      .catch(error => {
        console.log(error);
      })
    },
    downloadFormat(format){
      this.$store.dispatch('download', { slug: this.asset.asset_category_key, hash: format.hash })
      .then(response => {
         return this.$store.dispatch('downloadFromUrl', { url: response.data.url })
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')
        link.href = url;
        link.setAttribute('download', this.asset.title + '-' + format.format.name + '.mp4')
        link.click()
      })
      .catch(error => {
        console.log(error);
      })
    }
  },
}
</script>