<template>
	<div id="asset">
		<b-container v-if="category && asset && loaded">
			<b-row>
				<b-col class="text-center">
					<router-link class="h3" :to="{ name: 'category', params: { slug: type.uuid ? category.category_key : category.slug }}">{{ category.title }}</router-link>
				</b-col>
			</b-row>
			<div class="content">
				<b-row class="py-3" align-h="center">
					<b-col 	cols="12"
							v-if="asset.type !== 2"
							:md="asset.ratio === '1:1' ? '9' : asset.ratio === '4:3' ? '11' : '12'"
							:lg="asset.ratio === '1:1' ? '8' : asset.ratio === '4:3' ? '10' : '12'">
						<div class="bg-black embed-responsive" :class="getRatio().class">
							<iframe class="embed-responsive-item" :src="src" allowfullscreen="allowfullscreen"></iframe>
						</div>
					</b-col>
					<b-col cols="12" v-else>
						<b-img :src="asset.download_url" class="display-img"/>
					</b-col>
				</b-row>
				<b-row class="pt-4">
					<b-col class="col-12 col-lg-8">
						<h2 class="title">{{ asset.title }}</h2>
						<p>{{ asset.description }}</p>
					</b-col>
					<Downloads></Downloads>
				</b-row>
				<Assets></Assets>
			</div>
		</b-container>
		<b-container v-else>
			<Loading></Loading>
		</b-container>
	</div>
</template>
<script>
	import Assets 		from '@/components/Assets.vue'
	import Downloads 	from '@/components/Downloads.vue'
	import Loading 		from '@/components/Loading.vue'
	export default {
		components: {
			Assets,
			Downloads,
			Loading
		},
		computed: {
			src() {
				return this.asset.type === 4 && this.asset.broadcasting !== 3
					? this.env.VUE_APP_PLAYER_SRC + '?client_key=' + this.asset.client_key + '&asset_key=' + this.asset.asset_key + '&live=true' + this.getRatio().parameter + "&fetch=true"
					: this.env.VUE_APP_PLAYER_SRC + '?api_key=' + this.env.VUE_APP_API_KEY +'&slug=' + this.asset.slug + this.getRatio().parameter + "&fetch=true"
			},
			env() {
				return process.env
			},
			category() {
				return this.$store.getters.category
			},
			asset() {
				return this.$store.getters.asset
			},
			password() {
				return this.$store.getters.password
			},
			loaded() {
				return this.$store.getters.loaded
			},
			type() {
				return this.$store.getters.type
			}
		},
		methods: {
			getAsset() {
				this.$store.dispatch('loaded', false)
				this.$store.dispatch('asset', { slug: this.getSlug(), password: this.password })
				.then(response => {
					return this.$store.dispatch('category', { slug: this.type.uuid ? response.category.category_key : response.category.slug, password: this.password })
				})
				.then(response => {
					return this.$store.dispatch('assets', { slug: response.category_key, category_id : response.id, password: this.password })
				})
				.catch(err => {
					this.handleError(err.response.status)
				})
				.finally(() => {
					this.$store.dispatch('loaded', true)
				})
			},
			getSlug() {
				const path = window.location.pathname
				const slug = this.$route.params.slug ? this.$route.params.slug : path.substring(path.lastIndexOf('/') + 1)
				return slug
			},
			handleError(status) {
				if (status === 403) {
					this.$router.push({ name: 'login', params: { slug: this.getSlug(), name: this.$route.name }})
				} else {
					this.$router.push({ name: 'notfound' })
				}
			},
			getRatio() {
				console.log(['1:1', '4:3', '21:9', '40:17'].includes(this.asset.ratio))
				if (['1:1', '4:3', '21:9', '40:17'].includes(this.asset.ratio)) {
					const format = this.asset.ratio.split(':')
					return { parameter: `&format=f${format[0]}by${format[1]}`, class: `embed-responsive-${format[0]}by${format[1]}` }
				}
				return { parameter: '&format=f16by9', class: 'embed-responsive-16by9' }
			},
			getType() {
				this.$store.dispatch("type", this.$route.params.slug)
					.then(() => this.getAsset())
					.catch(err => {
						this.$router.push({ name: 'notfound', params: { error: err } })
					})
			},
		},
		mounted() {
			!this.type 
				? this.getType()
				: this.getAsset()
		}
	}
</script>
