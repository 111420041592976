var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.categories && _vm.categories.length > 0
    ? _c(
        "div",
        { staticClass: "categories" },
        [
          _c("hr"),
          _c(
            "b-row",
            { staticClass: "pt-4" },
            [_c("b-col", [_c("h4", [_vm._v("Categories")])])],
            1
          ),
          _c(
            "b-row",
            { staticClass: "pb-4" },
            _vm._l(_vm.filtered, function (category) {
              return _c(
                "b-col",
                {
                  key: category.id,
                  staticClass: "col-6 col-lg-4 pb-3",
                  on: {
                    click: function ($event) {
                      return _vm.goToCategory(category)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        class: category.thumbnail_url ? "no-icon" : "",
                      },
                      [
                        category.thumbnail_url
                          ? _c("img", {
                              attrs: { src: category.thumbnail_url },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c("p", {
                      staticClass: "title mb-0 pt-1",
                      domProps: { innerHTML: _vm._s(category.title) },
                    }),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }